export class OfficeTranslation {
  id: string | null
  locale: string
  title: string
  description: string
  address: string
  city: string
  area: string

  constructor({ id = null, locale = '', title = '', description = '', address = '', city = '', area = '' } = {}) {
    this.id = id
    this.locale = locale
    this.title = title
    this.description = description
    this.address = address
    this.city = city
    this.area = area
  }
}
