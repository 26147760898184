import { OfficeTranslation } from '@/models/OfficeTranslation'
/* eslint-disable camelcase */
export class Office {
  id: string;
  translations: OfficeTranslation[];
  code: string;
  name: string;
  main_phone: string;
  secondary_phone: string;
  fax: string;
  main_email: string;
  secondary_email: string;
  address_number: string;
  post_code: string;
  logo: string;
  location: any;
  owner: string;
  date_created: number;
  date_updated: number;
  meta: any;

  constructor(data: any = {}) {
    this.id = data.id
    this.code = data.code
    this.name = data.name
    this.main_phone = data.main_phone
    this.secondary_phone = data.secondary_phone
    this.fax = data.fax
    this.main_email = data.main_email
    this.secondary_email = data.secondary_email
    this.address_number = data.address_number
    this.post_code = data.post_code
    this.logo = data.logo
    this.location = data.location || { type: 'Point', coordinates: [] }
    this.owner = data.owner
    this.date_created = data.date_created
    this.date_updated = data.date_updated
    this.translations = data.translations
    this.meta = data.meta || {}
  }
}
