




















































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { deleteOffice, getOffice, saveOffice, saveOfficeWithMedia } from '@/api/offices'
import Sticky from '@/components/Sticky/index.vue'
import { Form } from 'element-ui'
import {
  locales,
  convertToMapTranslations,
  convertToArrayTranslations,
  validateForm,
  errorMsg,
  successMsg, confirmDialog, extractLatLongFromLocation
} from '@/utils'
import { Office } from '@/models/Office'
import MarkerMap from '@/components/GoogleMaps/marker-map.vue'
import { OfficeTranslation } from '@/models/OfficeTranslation'
import Tinymce from '@/components/Tinymce/index.vue'
import { AppModule, DeviceType } from '@/store/modules/app'
import { services } from '@/utils/property'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'OfficeDetail',
  components: {
    MarkerMap,
    Tinymce,
    Sticky
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  private office = new Office();
  private translations = convertToMapTranslations([], OfficeTranslation)
  private skeletonLoader = false
  private saveLoader = false
  private languages = locales
  private language = locales[0]
  private imageUrl = ''
  private imageUploaded: any = undefined
  private services = services
  private socialMedia = ['facebook', 'linkedin', 'instagram', 'youtube']
  private atLeastOnePhone = (rule: any, value: string, callback: Function) => {
    return this.hasAtLeastOnPhone() ? callback() : callback(this.$t('form.atLeastOnePhone'))
  }

  private rules = {
    name: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }],
    main_email: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }, {
      type: 'email',
      message: this.$t('form.isEmail'),
      trigger: 'blur'
    }],
    secondary_email: [{
      type: 'email',
      message: this.$t('form.isEmail'),
      trigger: 'blur'
    }],
    main_phone: [{
      validator: this.atLeastOnePhone,
      trigger: 'blur'
    }],
    secondary_phone: [{
      validator: this.atLeastOnePhone,
      trigger: 'blur'
    }]
  }

  created() {
    if (this.isEdit && this.$route.params && this.$route.params.id) {
      return this.getItem(this.$route.params.id)
    }
  }

  get center() {
    if (this.office && this.office.location) {
      return extractLatLongFromLocation(this.office.location)
    }
  }

  get pageTitle() {
    return this.isEdit
      ? this.$t('officeDetail.editOffice')
      : this.$t('officeDetail.newOffice')
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private hasAtLeastOnPhone() {
    return this.office.main_phone || this.office.secondary_phone
  }

  private handleImageSuccess(res: any, file: any) {
    this.imageUploaded = file
    this.imageUrl = URL.createObjectURL(file.raw)
  }

  private async getItem(id: string) {
    this.skeletonLoader = true
    try {
      const { data } = await getOffice({
        id: id
      })
      this.office = data
      this.translations = Object.assign(
        this.translations,
        convertToMapTranslations(
          data.translations,
          OfficeTranslation
        )
      )
      if (this.office.logo != null) {
        this.imageUrl = '/static/offices/' + this.office.id + '/images/' + this.office.logo
      } else {
        this.imageUrl = '/static/public/placeholder.png'
      }
    } catch (err) {
      await errorMsg('api.entityNotFound')
    }
    this.skeletonLoader = false
  }

  private onLocationChange(coordinates: any) {
    this.office.location.coordinates = coordinates
  }

  private async deleteItem() {
    const [data] = await confirmDialog('officeList.delete')
    if (!data) {
      return
    }
    try {
      await deleteOffice({
        id: this.office.id
      })
      await successMsg('officeList.deleteSuccess')
      return this.$router.push('/office/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async submitForm() {
    const [valid] = await validateForm(this.$refs.form as Form)
    if (!valid) {
      return errorMsg('form.formErrors')
    }
    this.saveLoader = true
    this.office.owner = this.office.owner || UserModule.id
    this.office.translations = convertToArrayTranslations(this.translations, OfficeTranslation)
    try {
      let data
      if (this.imageUploaded) {
        const formData = new FormData()
        formData.append('office', JSON.stringify(this.office))
        formData.append('image', this.imageUploaded.raw)
        data = (await saveOfficeWithMedia(formData)).data
      } else {
        data = (await saveOffice(this.office)).data
      }
      if (!this.isEdit) {
        return this.$router.push('/office/edit/' + data.id)
      }
      await successMsg('officeDetail.successSave')
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.saveLoader = false
  }
}
